import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "_heading-1 mb-2" }
const _hoisted_2 = { class: "_heading-3 mb-5" }
const _hoisted_3 = { class: "p-field mb-4" }
const _hoisted_4 = { class: "p-label" }
const _hoisted_5 = { class: "mt-4 _text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.$t('login_page.forgot_password')), 1),
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t('login_page.restore_msg')), 1),
    _createElementVNode("form", {
      onSubmit: _cache[1] || (_cache[1] = _withModifiers(($event: any) => ($setup.handleSubmit()), ["prevent"]))
    }, [
      _createElementVNode("label", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('email')), 1),
        _createVNode(_component_InputText, {
          type: "email",
          modelValue: $setup.email,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.email) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _createVNode(_component_Button, {
        disabled: $setup.support.isLoading,
        type: "submit",
        class: "_full-width"
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('login_page.restore_password')), 1)
        ]),
        _: 1
      }, 8, ["disabled"]),
      _createElementVNode("p", _hoisted_5, [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('login_page.remembered')), 1),
        _createVNode(_component_router_link, {
          to: { name: 'Login' },
          class: "_link _link-secondary _text-uppercase _text-bold ml-2"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('login_page.sign_in')), 1)
          ]),
          _: 1
        })
      ])
    ], 32)
  ], 64))
}