
import cookies from 'js-cookie';
import { useRouter } from 'vue-router';
import { reactive, ref } from 'vue';

import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import { useStores } from '@/store/Stores';

async function postData(url = '', data = {}) {
  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });
  return await response.json();
}

export default {
  components: {
    InputText,
    Button
  },
  setup() {
    const { accountStore } = useStores();

    const router = useRouter();

    const email = ref();
    const support = reactive({
      isLoading: false
    });

    const handleSubmit = () => {
      support.isLoading = true;
      postData('https://caiman.inflowcompany.com/api/auth/login', { email })
        .then((data) => {
          support.isLoading = false;
          // { expires: data.expires_in / 8.64e+7 }
          cookies.set('token', data.access_token);
          cookies.set('user', JSON.stringify(data.user));
          cookies.set('no-refresh', true);

          accountStore.setAccount(data.user);

          router.push({ path: '/' });
        })
        .catch((error) => {
          support.isLoading = false;
          console.log('error', error);
        });
    };

    return {
      email,
      handleSubmit,
      support
    };
  }
};
